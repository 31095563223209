import React, { useEffect, useState } from 'react';
import { Box, Image } from '@chakra-ui/react';
import { motion, AnimatePresence } from 'framer-motion';
import { useLocation } from 'react-router-dom';
import wolfImage from './images/wolf.png';

const MotionBox = motion(Box);
const MotionImage = motion(Image);

const tooltipVariants = {
    hidden: { opacity: 0 },
    visible: { opacity: 1, transition: { duration: 1.5 } },
    exit: { opacity: 0, transition: { duration: 1.5 } }
};

const characterVariants = {
    hidden: { opacity: 0, x: 100 },
    visible: { opacity: 1, x: 0, transition: { type: 'spring', stiffness: 120, duration: 1, delay: 1 } },
    exit: { opacity: 0, x: 100, transition: { type: 'spring', stiffness: 120, duration: 1 } }
};

const WolfCharacter = () => {
    const location = useLocation();
    const [showWolf, setShowWolf] = useState(true);

    useEffect(() => {
        const scrollTimeout = setTimeout(() => {
            window.scrollTo({ top: 0, behavior: 'smooth' });
        }, 5000);

        return () => clearTimeout(scrollTimeout);
    }, []);

    useEffect(() => {
        const timer = setTimeout(() => {
            setShowWolf(false);
        }, 5000);

        return () => clearTimeout(timer);
    }, []);

    // Condicional para mostrar el componente solo en la ruta raíz
    if (location.pathname !== '/') {
        return null;
    }

    return (
        <AnimatePresence>
            {showWolf && (
                <MotionBox
                    position="fixed"
                    bottom="80px"
                    right="10px"
                    zIndex="2000"
                    initial="hidden"
                    animate="visible"
                    exit="exit"
                    variants={characterVariants}
                >
                    <MotionImage
                        src={wolfImage}
                        alt="Wolf Character"
                        height={{ base: "30vh", md: "50vh" }}
                        objectFit="contain"
                        filter="drop-shadow(0 0 20px rgba(0, 0, 0, 0.5))"
                    />
                    <MotionBox
                        position="absolute"
                        top={{ base: "-110px", md: "-150px" }}
                        left={{ base: "-60px", md: "-120px" }}
                        width={{ base: "200px", md: "250px" }}
                        bg="black"
                        p={4}
                        borderRadius="3xl"
                        shadow="2xl"
                        fontSize={{ base: "md", md: "2xl" }}
                        color="white"
                        initial="hidden"
                        animate="visible"
                        exit="exit"
                        variants={tooltipVariants}
                    >
                        Llena el formulario y nos vemos más adelante en el tour
                    </MotionBox>
                </MotionBox>
            )}
        </AnimatePresence>
    );
};

export default WolfCharacter;
