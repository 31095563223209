import React from "react";
import { Center, Spinner, Text } from "@chakra-ui/react";

const LoadingScreen = () => (
  <Center minHeight="100vh" flexDirection="column">
    <Spinner size="xl" thickness="4px" speed="0.8s" color="black" />
    <Text mt={4} fontSize="xl" color="gray.500">
      Loading...
    </Text>
  </Center>
);

export default LoadingScreen;
