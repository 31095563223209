import React from "react";
import { Box } from "@chakra-ui/react";
import { motion } from "framer-motion";
import { useLocation } from "react-router-dom";

const MotionBox = motion(Box);

const ContentWrapper = ({ children }) => {
  const location = useLocation();

  return (
    <MotionBox
      key={location.pathname}
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -20 }}
      transition={{ duration: 0.5, ease: "easeInOut" }}
      width="100%"
    >
      {children}
    </MotionBox>
  );
};

export default ContentWrapper;
