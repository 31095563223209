import React from 'react';
import './PixelStreaming.css';

const PixelStreaming = ({ port }) => {
  const src = port ? `https://streaming.wolftowersproject.com:${port}` : null;

  return (
    <div className='iframe-contenedor'>
      {src ? (
        <iframe
          className="iframe-style"
          src={src}
          title="Pixel Streaming"
        ></iframe>
      ) : (
        <p>Loading iframe...</p>
      )}
    </div>
  );
}

export default PixelStreaming;
