// BottomBar.jsx
import React from 'react';
import { Box, Image } from '@chakra-ui/react';
import { motion } from 'framer-motion';
import barImage from './images/bar.png';

const MotionBox = motion(Box);

const BottomBar = () => {
    return (
        <MotionBox
            position="fixed" // Cambiado de sticky a fixed
            bottom="0"
            width="100%"
            zIndex="1000"
            backgroundColor="white"
            display="flex"
            justifyContent="center"
            p={{ base: 2, md: 3 }}
            // Removido initial y animate para pruebas
            transition={{ type: 'spring', stiffness: 120, duration: 1 }}
            overflow="hidden" // Agregado para evitar problemas en móviles
            will-change="transform" // Mejora el rendimiento de la animación
        >
            <Box
                width={{ base: '100%', md: '60%', '2xl': '25%' }}
                maxWidth="1200px"
                mx="auto"
                sx={{
                    '@media (min-width: 1256px) and (max-width: 2091px)': {
                        width: '33%' // Ajusta este valor según sea necesario
                    }
                }}
            >
                <Image src={barImage} alt="Sticky Bar" width="100%" height="33px" objectFit="contain" />
            </Box>
        </MotionBox>
    );
};

export default BottomBar;