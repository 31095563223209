import React from 'react';
import { Box, HStack, Image, Text } from '@chakra-ui/react';
import { motion } from 'framer-motion';
import Formulario from './formulario/Formulario';
import exampleImage from './images/form-img.jpg';

const MotionBox = motion(Box);
const MotionText = motion(Text);

const MainContent = () => {
    return (
        <>
            <MotionBox
                width={{ base: '90%', md: '70%' }}
                mx="auto"
                mt={{ base: -36, sm: -80, md: -20 }}
                mb={2}
                p={2}
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ type: 'spring', stiffness: 120, duration: 1 }}
            >
                <MotionText
                    fontSize={{ base: 'lg', md: 'xl' }}
                    fontWeight="bold"
                    fontFamily="Century Gothic, sans-serif"
                    textAlign="center"
                    p={1}
                >
                    Origin By Wolf
                </MotionText>
                <MotionText
                    fontSize={{ base: 'md', md: 'lg' }}
                    textAlign="center"
                    p={1}
                >
                    Vive Estilo Resort en tu Depa de Lujo en Cancún con Amenidades Únicas en el Mercado 🌴
                </MotionText>
            </MotionBox>
            <Box
                flex="1"
                display="flex"
                alignItems="center"
                justifyContent="center"
                p={0}
                width={{ base: '100%', md: '95%' }}
                mx="auto"
                mt={{ base: 2, md: 8 }} // Modificado aquí
            >
                <HStack
                    spacing={{ base: 4, md: 8 }}
                    justifyContent="center"
                    alignItems="center"
                    flexDirection={{ base: 'column', md: 'row' }}
                    width="100%"
                >
                    <MotionBox
                        initial={{ opacity: 0, x: -50, scale: 0.9 }}
                        animate={{ opacity: 1, x: 0, scale: 1 }}
                        transition={{ type: 'spring', stiffness: 120, duration: 1 }}
                        display={{ base: 'none', md: 'block' }}
                    >
                        <Image src={exampleImage} alt="Origin By Wolf" boxSize={{ base: "250px", md: "450px" }} objectFit="cover" />
                    </MotionBox>
                    <MotionBox
                        initial={{ opacity: 0, x: 50, scale: 0.9 }}
                        animate={{ opacity: 1, x: 0, scale: 1 }}
                        transition={{ type: 'spring', stiffness: 120, duration: 1 }}
                        width={{ base: '100%', md: 'auto' }}
                    >
                        <Formulario />
                    </MotionBox>
                </HStack>
            </Box>
        </>
    );
};

export default MainContent;