// LogoBar.jsx
import React from 'react';
import { Flex, Image, Spacer, Box } from '@chakra-ui/react';
import { motion } from 'framer-motion';
import leftLogo from './images/origin-logo.png';
import rightLogo from './images/wolf-logo.png';

const MotionBox = motion(Box);
const MotionFlex = motion(Flex);

const LogoBar = () => {
    return (
        <MotionBox
            width={{ base: '100%', md: '70%' }}
            mx="auto"
            top="0"
            zIndex="1000"
            initial={{ y: -100 }}
            animate={{ y: 0 }}
            transition={{ type: 'spring', stiffness: 120, duration: 1 }}
        >
            <MotionFlex
                as="nav"
                width="100%"
                backgroundColor="transparent"
                py={2}
                px={{ base: 4, md: 8 }}
                alignItems="center"
            >
                <Image
                    src={leftLogo}
                    alt="Left Logo"
                    maxHeight={{ base: "40px", md: "87px" }}
                    p={{ base: 1, md: 5 }}
                />
                <Spacer />
                <Image
                    src={rightLogo}
                    alt="Right Logo"
                    maxHeight={{ base: "40px", md: "87px" }}
                    p={{ base: 1, md: 5 }}
                />
            </MotionFlex>
        </MotionBox>
    );
};

export default LogoBar;