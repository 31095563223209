import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const useRegistroForm = () => {
  const [nombre, setNombre] = useState('');
  const [apellido, setApellido] = useState('');
  const [telefono, setTelefono] = useState('');
  const [correo, setCorreo] = useState('');
  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();

    try {
      // Aquí se agregará la lógica para registrar el formulario en el futuro.
      console.log("Formulario enviado: ", { nombre, apellido, telefono, correo });

      // Ejemplo de navegación a una ruta futura
      navigate('/experience');
    } catch (error) {
      console.error("Error al enviar el formulario: ", error);
    }
  };

  return { nombre, setNombre, apellido, setApellido, telefono, setTelefono, correo, setCorreo, handleSubmit };
};

export default useRegistroForm;