import { useState, useEffect } from "react";

const useFetchPort = (id) => {
  const [port, setPort] = useState(null);

  const fetchPort = async (id) => {
    console.log(`Buscando puerto para ID: ${id}`);
    const response = await fetch(`/api/getPort/${id}`);
    const data = await response.json();
    if (data && data.port) {
      setPort(data.port);
      console.log("Puerto recibido desde el backend: ", data.port);
    } else {
      console.log("El documento no tiene el campo 'port' aún.");
      setTimeout(() => fetchPort(id), 1000);
    }
  };

  useEffect(() => {
    if (id) {
      fetchPort(id);
    }
  }, [id]);

  return port;
};

export default useFetchPort;
