import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

const useFetchFullDocId = () => {
  const location = useLocation();
  const [fullDocId, setFullDocId] = useState(null);

  useEffect(() => {
    const pathArray = location.pathname.split("/");
    const shortId = pathArray.length > 2 ? pathArray[2] : null;

    if (shortId) {
      const getFullDocId = async () => {
        const response = await fetch(`/api/getFullDocId/${shortId}`);
        const data = await response.json();
        if (data && data.fullDocId) {
          const fullDocId = data.fullDocId;
          console.log(`Doc ID completo obtenido: ${fullDocId}`);
          setFullDocId(fullDocId);
        } else {
          console.log("No se encontró el documento.");
        }
      };
      getFullDocId();
    }
  }, [location.pathname]);

  return fullDocId;
};

export default useFetchFullDocId;
