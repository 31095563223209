//Formulario.jsx
import React from 'react';
import {
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  InputLeftAddon,
  Button,
  VStack,
  HStack,
  Box,
  Heading,
  Image,
} from '@chakra-ui/react';
import { ArrowForwardIcon } from '@chakra-ui/icons';
import { motion } from 'framer-motion';
import useRegistroForm from './hooks/useRegistroForm';
import ComputoLogo from '../images/computo-logo.png';

const MotionBox = motion(Box);
const MotionVStack = motion(VStack);
const MotionHStack = motion(HStack);
const MotionHeading = motion(Heading);
const MotionFormControl = motion(FormControl);
const MotionInput = motion(Input);
const MotionButton = motion(Button);

const Formulario = () => {
  const { nombre, setNombre, apellido, setApellido, telefono, setTelefono, correo, setCorreo, handleSubmit } = useRegistroForm();
  const [codigoPais, setCodigoPais] = React.useState('');

  const handleFormSubmit = (e) => {
    e.preventDefault();
    setTelefono(`+${codigoPais}${telefono}`);
    handleSubmit(e);
  };

  const transition = { duration: 0.4, ease: "easeInOut" };

  return (
    <MotionBox minH="100%" display="flex" flexDirection="column" alignItems="center" justifyContent="center" p={2} initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={transition}>
      <MotionVStack as="form" onSubmit={handleFormSubmit} spacing={2} p={8} borderRadius="lg" boxShadow="xl" borderLeftWidth="5px" borderLeftColor="black" width={{ base: "92%", md: "500px" }} initial={{ y: 20, opacity: 0 }} animate={{ y: 0, opacity: 1 }} transition={{ duration: 0.5, ease: "easeOut" }}>
        <MotionHeading as="h1" size="xl" textAlign="center" initial={{ scale: 0.9, opacity: 0 }} animate={{ scale: 1, opacity: 1 }} transition={{ duration: 0.5, ease: "easeOut" }}>
          Acceso exclusivo a nuestro Tour Virtual
        </MotionHeading>
        <MotionHStack spacing={2} width="100%" alignItems="flex-end" initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 0.5, ease: "easeOut", delay: 0.1 }}>
          <MotionFormControl isRequired flex="1" initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 0.5, ease: "easeOut", delay: 0.2 }}>
            <FormLabel htmlFor="nombre">Nombre</FormLabel>
            <MotionInput id="nombre" type="text" value={nombre} onChange={(e) => setNombre(e.target.value)} placeholder="Nombre" initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 0.5, ease: "easeOut", delay: 0.3 }} />
          </MotionFormControl>
          <MotionFormControl flex="1" initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 0.5, ease: "easeOut", delay: 0.4 }}>
            <MotionInput id="apellidos" type="text" value={apellido} onChange={(e) => setApellido(e.target.value)} placeholder="Apellidos" initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 0.5, ease: "easeOut", delay: 0.5 }} />
          </MotionFormControl>
        </MotionHStack>
        <MotionFormControl isRequired initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 0.5, ease: "easeOut", delay: 0.6 }}>
          <FormLabel htmlFor="telefono">Número de Teléfono</FormLabel>
          <MotionHStack spacing={2} width="100%" initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 0.5, ease: "easeOut", delay: 0.7 }}>
            <InputGroup width="36%">
              <InputLeftAddon children="+" />
              <MotionInput id="codigoPais" type="number" value={codigoPais} onChange={(e) => setCodigoPais(e.target.value)} placeholder="52" initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 0.5, ease: "easeOut", delay: 0.8 }} />
            </InputGroup>
            <MotionInput id="telefono" type="tel" value={telefono.replace(`+${codigoPais}`, '')} onChange={(e) => setTelefono(e.target.value)} placeholder="Número de Teléfono" flex="1" inputMode="tel" initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 0.5, ease: "easeOut", delay: 0.9 }} />
          </MotionHStack>
        </MotionFormControl>
        <MotionFormControl isRequired initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 0.5, ease: "easeOut", delay: 1 }}>
          <FormLabel htmlFor="correo">Correo Electrónico</FormLabel>
          <MotionInput id="correo" type="email" value={correo} onChange={(e) => setCorreo(e.target.value)} placeholder="Correo Electrónico" initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 0.5, ease: "easeOut", delay: 1.1 }} />
        </MotionFormControl>
        <MotionButton rightIcon={<ArrowForwardIcon />} backgroundColor="#000000" color="white" _hover={{ backgroundColor: "#333333" }} _active={{ backgroundColor: "#000000" }} type="submit" size="lg" width="full" initial={{ scale: 0.95 }} animate={{ scale: 1 }} transition={{ duration: 0.5, ease: "easeOut", delay: 1.2 }} whileHover={{ scale: 1.03 }} whileTap={{ scale: 0.97 }}>
          Ver Ahora
        </MotionButton>
        <MotionBox
          display="flex"
          justifyContent="center"
          width={{ base: '100%', md: '70%' }}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5, ease: "easeOut", delay: 1.3 }}
          p={{ base: 2, md: 4 }}
        >
          <Image
            src={ComputoLogo}
            alt="Computo Logo"
            width={{ base: '80%', md: '90%' }}
            p={{ base: 4, md: 3 }}
          />
        </MotionBox>
      </MotionVStack>
    </MotionBox>
  );
};

export default Formulario;
