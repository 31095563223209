import React, { useEffect, useState } from 'react';
import { Box } from '@chakra-ui/react';
import { useLocation } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';
import LogoBar from './LogoBar';
import BottomBar from './BottomBar';
import WolfCharacter from './WolfCharacter';
import MainContent from './MainContent';
import PixelStreaming from './pixel_streaming/PixelStreaming';
import LoadingScreen from './LoadingScreen';
import ContentWrapper from './ContentWrapper';
import useFetchPort from '../hooks/useFetchPort';
import useFetchFullDocId from '../hooks/useFetchFullDocId';

function Main({ isExperienceRoute: propIsExperienceRoute, isLoading: propIsLoading, port: propPort }) {
    const location = useLocation();
    const [isLoading, setIsLoading] = useState(true);

    const fullDocId = useFetchFullDocId();
    const fetchedPort = useFetchPort(fullDocId);
    const port = propPort !== undefined ? propPort : fetchedPort;

    const isExperienceRoute = propIsExperienceRoute !== undefined ? propIsExperienceRoute : location.pathname.startsWith('/experience');

    useEffect(() => {
        setIsLoading(true);
        const loadTimer = setTimeout(() => setIsLoading(false), 500);

        return () => clearTimeout(loadTimer);
    }, [location.pathname]);

    const loadingState = propIsLoading !== undefined ? propIsLoading : isLoading;

    return (
        <Box minH="100vh" display="flex" flexDirection="column" position="relative">
            <LogoBar />
            <Box flex="1" display="flex" justifyContent="center" alignItems="center" position="relative">
                <AnimatePresence mode="wait">
                    <ContentWrapper key={location.pathname}>
                        {loadingState ? (
                            <LoadingScreen />
                        ) : isExperienceRoute ? (
                            <Box minHeight="100%" display="flex" justifyContent="center" alignItems="center">
                                <PixelStreaming port={port} />
                            </Box>
                        ) : (
                            <MainContent />
                        )}
                    </ContentWrapper>
                </AnimatePresence>
            </Box>
            <BottomBar style={{ position: 'fixed', bottom: 0, width: '100%' }} />
            <WolfCharacter />
        </Box>
    );
}

Main.defaultProps = {
    port: undefined,
    isLoading: undefined,
    isExperienceRoute: undefined,
};

export default Main;